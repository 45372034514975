body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
p,
div,
span {
    font-family: Barlow;
}

a {
    text-decoration: none;
}


@media print {
    body {
        background-color: #64748B;
        -webkit-print-color-adjust: exact !important;
    }
}
