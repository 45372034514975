.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-radius: 5px!important;
}

.MuiTableContainer-root::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
  background-color:#D9D9D9;
  border-radius: 4px;
  scroll-padding: 20px;
}
.MuiTableContainer-root::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color:#A2A2A2;
  scroll-padding: 20px;
}